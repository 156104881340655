import React from "react";

function RedditMobile() {
  return (
    <>
      <section className="app1_section pb-4 bg2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6  ">
              <div className="legends_left mt-5">
                <p className="fw-light">
                  Born in the <b>darkest corners of Reddit</b>💀, powered by
                  caffeine and “it’s just a phase, Mom,” we’re here to turn
                  every gamer, memer, and degenerate into a MemeClip legend.
                </p>
                <b>Join us. Or regret later. muhahahaha!</b>
              </div>
            </div>
            <div className="col-md-6 ">
              <img src="/assets/img/app1.png" alt="app" className="w105" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RedditMobile;
