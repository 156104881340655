import React from "react";

function Header() {
  return (
    <>
      <nav className="navbar navbar-expand-lg nav_fix bg1">
        <div className="container">
          <div className="nav_box">
            <div className="d-lg-flex p-3 align-items-center">
              <div className="d-flex ms-lg-auto mb-3 mb-lg-0">
                <a className="navbar-brand  d-inline-block" href="#">
                  {" "}
                  <img src="/assets/img/logo.svg" alt="Logo" />
                </a>
                <button
                  className="navbar-toggler ms-auto"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsibleNavbar"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>
              <div
                className="collapse navbar-collapse m-auto"
                id="collapsibleNavbar"
              >
                <ul className="navbar-nav  ">
                  <li className="nav-item">
                    <a className="nav-link active" href="#">
                      {" "}
                      About
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      Roadmap
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      Tokenomics
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      FAQ{" "}
                    </a>
                  </li>
                </ul>
              </div>
              <a className="btn btn1 m-lg-auto" href="#">
                Buy $MCLIP
              </a>
              <div className="right_social m-auto">
                <a href="#">
                  <i className="bi bi-twitter-x"></i>
                </a>
                <a href="#">
                  <i className="bi bi-send-fill"></i>
                </a>
                <a href="#">
                  <i className="bi bi-discord"></i>
                </a>
              </div>
            </div>
            <div className="top_bottom">
              Buy before DEX launch and Claim $MCLIP{" "}
              <img
                src="/assets/img/rocket.png"
                alt="rocket"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
