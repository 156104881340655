import React from "react";

function Fortune() {
  return (
    <>
      <section className="fortune_bx position-relative">
        <div className="container ">
          <div className="row align-items-center">
            <div className="col-md-5 mb-4 mb-md-0 ">
              <img src="/assets/img/fortune.png" alt="waht" className="w100" />
            </div>
            <div className="col-md-7 ps-md-5">
              <div className="rc_box">
                <h2 className="hadding mb-3 color4 font1">
                  FORTUNE QUICKIE GENERATOR
                </h2>
                <h3 className="color4 font2 fonth2 mb-4 mb-md-5">
                  LUCK OR F*CKED, OPEN TO FIND OUT!
                </h3>

                <a className="btn4" href="#">
                  OPEN NOW!
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Fortune;
