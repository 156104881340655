import React from "react";

function Infinite() {
  return (
    <>
      <section className="bg11 pt-md-3 pb-md-1 pt-5 pb-5">
        <div className="container ">
          <div className="row align-items-center">
            <div className="col-lg-5 ">
              <img
                src="/assets/img/waht.png"
                alt="waht"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-7 ps-lg-5">
              <div className="rc_box">
                <h2 className="hadding mb-3 color1 font1">
                  WAHT THE F**K IS MEMECLIP?
                </h2>
                <h3 className="color1 font2 fonth2 mb-4">
                  The Only Rule? Be the Meme.{" "}
                </h3>
                <p className="h5">
                  We’re MemeClip. The mad scientists of the internet who smashed
                  together Miniclip nostalgia, meme culture, and crypto gains
                  into one glorious chaos machine.
                </p>
                <h3 className="color1 fonth2 font2 mt-5">
                  This ain’t your grandma’s crypto project.
                  <br /> games + memes ={" "}
                  <span className="btn333">
                    <span className="btn33">
                      <a className="btn btn3 ls2" href="#">
                        infinite dopamine hits
                      </a>
                    </span>
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg2 pt-4 ">
        <div className="container mt-5">
          <h2 className="hadding mb-0 color2 text-center font1">
            100% SKILL, <span className="text-white font2">0% CHILL</span>
          </h2>
          <h5 className=" text-center color3 fw-medium">
            <span className="fw-light">fast, fun, and</span> occasionally makes
            sense.
          </h5>
        </div>
        <img src="/assets/img/skill.jpg" alt="skill" className="w100 mt-5" />
      </section>
    </>
  );
}

export default Infinite;
