import React from "react";
import Buy from "./Buy";

function Banner() {
  return (
    <>
      <section className="hero position-relative ">
        <div className="container pt-5">
          <div className="row">
            <div className="col-lg-8 text-lg-end">
              <div className="d-inline-block text-center">
                <h1 className="text-center font1"> $MCLIP</h1>
                <p className="fw300 mb-4 subhadding text-center font2">
                  {" "}
                  WHERE <span>MEMES</span> GAME HARDER THAN YOU DO!{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <Buy/>
            </div>
          </div>
        </div>
        <img src="/assets/img/hero.png" alt="u" className="hero_top" />
      </section>
    </>
  );
}

export default Banner;
