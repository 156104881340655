import React from "react";

function MarqueSlider() {

  return (
    <>

      <section className="position-relative ">
        <div className="scrool_logo1 ">
          <marquee>
            <div className="d-flex align-items-center">
              <img src="/assets/img/pmp.png" alt="pmp" />
              <img src="/assets/img/pmp.png" alt="pmp" />
              <img src="/assets/img/pmp.png" alt="pmp" />
            </div>
          </marquee>
        </div>
      </section>
    </>
  );
}

export default MarqueSlider;
