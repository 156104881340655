import React from 'react'

function Footer() {
  return (
    <>
       <section className="footer text-center" id="contact">
    <div className="container">
      <div className="row     align-items-center">
      
        <div className="col-md-6 text-md-start mb-4 mb-md-0">
          <img src="/assets/img/logo.svg" alt="Logo" className="footer_logo"/>
        </div>

        <div className="col-md-6 d-md-flex">
          <div className="right_social ms-md-auto">
            <a href="#"><i className="bi bi-twitter-x"></i></a>
            <a href="#"><i className="bi bi-send-fill"></i></a>
            <a href="#"><i className="bi bi-discord"></i></a>

          </div>
        </div>
        
      </div>
    </div>

  </section>
    </>
  )
}

export default Footer
