import React, { useEffect, useRef, useState } from "react";

function FrogSlider() {
  const [marginTop, setMarginTop] = useState(0);
  const secRef=useRef(null)
 
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY; // Current scroll position
      if (scrollY >= secRef.current.offsetTop) {
        setMarginTop((scrollY-secRef.current.offsetTop )/1.2)
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll); // Clean up event listener
    };
  }, []);

  return (
    <>

      <section class="road_map position-relative" ref={secRef}>
        <div class="container position-relative">
          <div class="row position-relative">
            <div class="col-md-4 mb-5">
              <h2 class="hadding mb-3 color5 text-center ttu font1">
                Meme Master Plan
              </h2>
              <div class="btn333 w100 ">
                <div class="btn33 w100 d-block">
                  <a
                    class="btn  btn3 ls2 text-center d-block w100 mui"
                    href="#"
                  >
                    for world domination
                  </a>
                </div>
              </div>
            </div>

            <div class="col-md-8 position-relative pb-5">
              <div class="road_scrool">
                <img src="/assets/img/r_icon.png" class="r_icon img-fluid" style={{ marginTop: `${marginTop}px` }}/>
              </div>
              <div class={`row mb-5 rm_box1 box-active ${marginTop>=-500 && marginTop<250?"active":""} position-relative`}>
                <div class="col-md-4">
                  <div class="r_number">
                    <span>
                      <h3 class="font1 mb-0">1</h3>
                    </span>
                  </div>
                </div>
                <div class="col-md-8 ">
                  <div class="rm_box">
                    <h3 class="font2 text-center mb-4">
                       Concept and
                      Development
                    </h3>
                    <ul>
                      <li>
                        Community creation, Social Launch, Finalize Games
                        Concept{" "}
                      </li>
                      <li>
                        {" "}
                        Website preparation and Launch, Launch of $MCLIP{" "}
                      </li>
                      <li>
                        Public Presale, Launch of Phase 1 Marketing Campaigns{" "}
                      </li>
                      <li>
                        Build community to 10,000+ members, Development of
                        MemeClip Web3 Beta App{" "}
                      </li>
                      <li> Start Testing of MemeClip App </li>
                    </ul>
                  </div>
                </div>
              </div>
              
              <div class={`row mb-5 rm_box1 ${marginTop>=250 && marginTop<600?"active":""}  position-relative`}>
                <div class="col-md-4">
                  <div class="r_number">
                    <span>
                      <h3 class="font1 mb-0">2</h3>
                    </span>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="rm_box">
                    <h3 class="font2 text-center mb-4">
                      {" "}
                      <img src="/assets/img/lock.png" alt="pmp" /> Token Listing
                      and App Launch
                    </h3>
                    <ul>
                      <li>
                        List $MCLIP on DEX and major CEX for more adoption{" "}
                      </li>
                      <li> Start of Phase 2 Marketing Campaigns</li>
                      <li>Official Launch of MemeClip Web3 App </li>
                      <li>
                        {" "}
                        Influencer Marketing Campaign Expand partnerships with
                        other crypto projects and meme creators, launch MemeClip
                        merchandise, and explore cross-platform rewards.
                      </li>
                      <li> Launch and First Virtual Tournament</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class={`row mb-5 rm_box1 ${marginTop>=600?"active":""} position-relative`}>
                <div class="col-md-4">
                  <div class="r_number">
                    <span>
                      <h3 class="font1 mb-0">3</h3>
                    </span>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="rm_box">
                    <h3 class="font2 text-center mb-4">
                      {" "}
                      <img src="/assets/img/lock.png" alt="pmp" /> Long-Term
                      Vision
                    </h3>
                    <ul>
                      <li> Staking Launch </li>
                      <li>
                        {" "}
                        Introduce new mini-games and features, expand NFT
                        offerings, and host major community events and
                        tournaments.{" "}
                      </li>
                      <li>
                        {" "}
                        Continuously update and enhance the MemeClip ecosystem
                        based on community feedback, technological advancements,
                        and market trends.{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FrogSlider;
