import React from "react";

function Buy() {
  return (
    <>
      <div className="buy_box">
        <h3 className="ttu font1">Presale Launch</h3>
        <div className="buy_box_in">
          <div id="clockdiv">
            <div className="t_box">
              <span className="days font1" id="day"></span>
              <div className="smalltext">Days</div>
            </div>
            <div className="t_box">
              <span className="hours font1" id="hour"></span>
              <div className="smalltext">Hours</div>
            </div>
            <div className="t_box">
              <span className="minutes font1" id="minute"></span>
              <div className="smalltext">Mins</div>
            </div>
            <div className="t_box">
              <span className="seconds font1" id="second"></span>
              <div className="smalltext">Sec</div>
            </div>
          </div>

          <div
            className="progress mt-3 position-relative"
            role="progressbar"
            aria-label="Basic example"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div className="progress-bar" style={{ width: "15%" }}></div>
            <span className="ul_span">Until Lambo</span>
          </div>
          <div className="text-center pt-4">
            <h6>$USDT Raised: $29,793,00/$1,425,7111 </h6>
            <h6> Your Purchased $MCLIP = 0</h6>
            <div className="position-relative line text-center mt-4">
              <div className="position-absolute">
                <h6 className="m-0">1 SMCLIP = $0.006132</h6>
              </div>
            </div>
          </div>
          <div className="buy_tab mt-4 text-center">
            <a href="#">
              {" "}
              <img src="/assets/img/eth.png" alt="eth" />
            </a>
            <a href="#">
              {" "}
              <img src="/assets/img/usdt.png" alt="usdt" />
            </a>
            <a href="#">
              {" "}
              <img src="/assets/img/binance.png" alt="binance" />
            </a>
          </div>
          <div className="row mt-3">
            <div className="col-md-6 mb-4 pe-md-2">
              <label className="fw700 mb-2 d-flex small ls-1">
                Select payment method <span className="ms-auto">max</span>
              </label>
              <div className="b_box b_box_p d-flex select_box position-relative  align-items-center">
                <img src="/assets/img/eth.png" className="card_img " />
                <input type="number" className="buy_input" />
              </div>
            </div>

            <div className="col-md-6 mb-4 ps-md-2">
              <label className="fw700 mb-2 d-flex small ls-1">
                Recieve SMCLIP <span className="ms-auto">max</span>
              </label>
              <div className="b_box b_box_p d-flex select_box position-relative  align-items-center">
                <img src="/assets/img/g.png" className="card_img " />
                <input type="number" className="buy_input" />
              </div>
            </div>
          </div>

          <button className="btn btn2 ttu w100  font1">Connect Wallet</button>
        </div>
      </div>
    </>
  );
}

export default Buy;
