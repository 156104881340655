import React from "react";

function SignUp() {
  return (
    <>
      <section className="pure_sec position-relative">
        <img
          src="/assets/img/pure_sec_top.png"
          alt="waht"
          className="w100 pure_sec_top position-absolute"
        />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7">
              <h2 className="hadding mb-3 color1 font1">
                SIGN UP AND <br />
                CLAIM YOUR FREE $MCLIP
              </h2>
              <h2 className="text-black font2">
                Big moves only.
                <span className="color1">Stonks only go up. 🚀</span>{" "}
              </h2>
            </div>
            <div className="col-md-4 ms-auto">
              <div className="sign_up_box  ">
                <div className="form-group ex_input_box position-relative">
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Ex: Maguire"
                    className="input_item"
                    value=""
                  />
                </div>
                <div className="form-group ex_input_box position-relative">
                  <label>Email</label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Ex: Maguire@memeclip.com"
                    className="input_item"
                    value=""
                  />
                </div>
                <div className="form-group ex_input_box position-relative">
                  <label>Phone No.</label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Ex: +91xxxxx03"
                    className="input_item"
                    value=""
                  />
                </div>

                <button
                  type="button"
                  className="btn btn2 font1 ttu w100 mt-3 text-center mb-auto"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SignUp;
