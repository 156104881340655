import React, { useEffect, useRef } from "react";

const StickyHorizontalScroll = () => {
  const secrefs=useRef(null)
  useEffect(() => {
    (function () {
      init();

      function init() {
        setStickyContainersSize();
        bindEvents();
      }

      function bindEvents() {
        window.addEventListener("wheel", wheelHandler);
      }

      function setStickyContainersSize() {
        document
          .querySelectorAll(".sticky-container")
          .forEach(function (container) {
            const stikyContainerHeight =
              container.querySelector("main").scrollWidth;
            container.setAttribute(
              "style",
              "height: " + stikyContainerHeight + "px"
            );
          });
      }

      function isElementInViewport(el) {
        const rect = el.getBoundingClientRect();
        return (
          rect.top <= 0 && rect.bottom > document.documentElement.clientHeight
        );
      }

      function wheelHandler(evt) {
        const containerInViewPort = Array.from(
          document.querySelectorAll(".sticky-container")
        ).filter(function (container) {
          return isElementInViewport(container);
        })[0];

        if (!containerInViewPort) {
          return;
        }

        var isPlaceHolderBelowTop =
          containerInViewPort.offsetTop < document.documentElement.scrollTop;
        var isPlaceHolderBelowBottom =
          containerInViewPort.offsetTop + containerInViewPort.offsetHeight >
          document.documentElement.scrollTop;
        let g_canScrollHorizontally =
          isPlaceHolderBelowTop && isPlaceHolderBelowBottom;

        if (g_canScrollHorizontally) {
          containerInViewPort.querySelector("main").scrollLeft += evt.deltaY;
        }
      }
    })();
  },[]);

  return (
    <>
      <div class="sticky-container" ref={secrefs}>
        <main>
          <section>
            <div class="carousel-inner1 slider1_section">
              <div class="container">
                <div class="carousel-item1 ">
                  <div class="row align-items-center">
                    <div class="col-md-4 ">
                      <img
                        src="/assets/img/rush_logo.png"
                        alt="app"
                        class="w100"
                      />
                      {/* <span class="number">//01</span> */}
                    </div>
                    <div class="col-md-8 ">
                      <div class="row align-items-center">
                        <div class="col-md-7">
                          <h5>
                            Sprint through a chaotic city as Grumpy Cat chases
                            you down! Collect meme goodies like “Epic Face
                            Masks” and dodge wild hazards with power-ups like
                            “Coin Cascade” and “Pout Pause.” Outrun your chaser
                            for epic rewards!
                          </h5>
                        </div>
                        <div class="col-md-5 ">
                          <img
                            src="/assets/img/slider1_app.png"
                            alt="app"
                            class="w100 slider1_app"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="carousel-inner1 slider2_section">
              <div class="container">
                <div class="carousel-item1">
                  <div class="row align-items-center">
                    <div class="col-md-4 ">
                      <img
                        src="/assets/img/pepes.png"
                        alt="app"
                        class="w100 "
                      />
                      {/* <span class="number">//02</span> */}
                    </div>
                    <div class="col-md-8">
                      <div class="row align-items-center">
                        <div class="col-md-7">
                          <h5>
                            Dive into chaotic billiards with Pepe and pals!
                            Launch meme-fueled shots like “Meme Blast” and
                            dominate the table with “Splash of Feels” and “Rapid
                            Fire.” Unlock hilarious outfits and battle for glory
                            in seasonal tournaments!
                          </h5>
                        </div>
                        <div class="col-md-5 ">
                          <img
                            src="/assets/img/slider2_app.png"
                            alt="app"
                            class="w100 slider1_app"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="carousel-inner1 slider3_section">
              <div class="container">
                <div class="carousel-item1">
                  <div class="row align-items-center">
                    <div class="col-md-4 ">
                      <img src="/assets/img/pop.png" alt="app" class="w100" />
                      {/* <span class="number">//03</span> */}
                    </div>
                    <div class="col-md-8">
                      <div class="row align-items-center">
                        <div class="col-md-7">
                          <h5>
                            Join PopCat, Doge, and friends in meme-powered
                            soccer action! Use “Snap Strike” to crush goals and
                            “Meme Mirage” to confuse defenders. Team up for
                            high-stakes tournaments and score meme-worthy
                            victories!
                          </h5>
                        </div>
                        <div class="col-md-5 ">
                          <img
                            src="/assets/img/slider3_app.png"
                            alt="app"
                            class="w100 slider1_app"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="carousel-inner1 slider4_section">
              <div class="container">
                <div class="carousel-item1">
                  <div class="row align-items-center">
                    <div class="col-md-4 ">
                      <img
                        src="/assets/img/dogekart.png"
                        alt="app"
                        class="w100"
                      />
                      {/* <span class="number">//04</span> */}
                    </div>
                    <div class="col-md-8">
                      <div class="row align-items-center">
                        <div class="col-md-7">
                          <h5>
                            Speed past Shiba and Pepe on meme-tastic tracks like
                            Moon Boulevard and Crypto Canyon. Use “Moon Drift”
                            and “Blockchain Shield” to leave your rivals in the
                            dust!
                          </h5>
                        </div>
                        <div class="col-md-5 ">
                          <img
                            src="/assets/img/slider4_app.png"
                            alt="app"
                            class="w100 slider1_app"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
};

export default StickyHorizontalScroll;
