import React from 'react'
import Footer from '../../widgets/Footer'
import Header from '../../widgets/Header'
import Banner from './Banner'
import Fortune from './Fortune'
import FrogSlider from './FrogSlider'
import Infinite from './Infinite'
import Join from './Join'
import MarqueSlider from './MarqueSlider'
import Noads from './Noads'
import RedditMobile from './RedditMobile'
import SignUp from './SignUp'
import StickyHorizontalScroll from './StickyHorizontalScroll'


function Home() {
  return (
    <>
      <Header/>
      <Banner/>
      <MarqueSlider/>
      <Infinite/>
      <MarqueSlider/>
      <RedditMobile/>
     <StickyHorizontalScroll/>
     <MarqueSlider/>
      <Noads/>
      <MarqueSlider/>
      <FrogSlider/>
      <Fortune/>
      <SignUp/>
      <Join/>
      <Footer/>
    </>
  )
}

export default Home
