import React, { useRef } from "react";

function Noads() {

  return (
    <>
      <section className="ovh ">
        <section className="bg2 no_ads ">
          <div className="container mt-md-5">
            <h2 className="hadding mb-3 color2 text-center ttu font1">
              No ads, <span className="text-white font2">no p2w gimmicks</span>
            </h2>
            <h5 className="color3 text-center">just pure meme carnage.</h5>
          </div>
          <img src="/assets/img/usual.jpg" alt="skill" className="w100" />
        </section>

        <section className="bg3 nomics_sec">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="position-relative cc2">
                  <img
                    src="/assets/img/chart.png"
                    alt="chart"
                    className="chart rotateme"
                  />
                  <img
                    src="/assets/img/chart2.png"
                    alt="chart"
                    className="chart2"
                  />
                </div>
              </div>
              <div className="col-md-4 mb-lg-0 mb-5 mt-lg-0 mt-4">
                <h2 className="hadding mb-3 color4 text-center ttu font1">
                  kroako Nomics
                </h2>
                <h4 className="color3 text-center ">
                  we like big pies <br />
                  and we cannot lie
                </h4>
              </div>
              <div className="col-md-4">
                <div className="nomics_box text-center">
                  <div className="total_supply">
                    <b>total supply</b> <br />
                    (because we like big numbers).
                    <h3 className="mt-2"> 5,000,000,000 MCLIP</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}

export default Noads;
