import React from "react";

function Join() {
  return (
    <>
      <section className="join_the position-relative">
        <div className="container text-center">
          <h2 className="hadding mb-3 color1 font1  ttu">
            Join The
            <br /> Memevolution <br />
            NOW!
          </h2>
          <p className=" color1 mb-4">
            Memes don’t wait. Neither should you. Be the first to snag
            <br /> $MCLIP tokens, flex your skills, and become the ultimate
            <br /> MemeClip legend.
          </p>
          <div className="d-inline-md-flex align-items-center">
            <input
              type="text"
              className="email_input me-md-3"
              placeholder="Email"
            />
            <button className="btn btn1" type="submit">
              JOIN NOW!
            </button>
          </div>
        </div>
      </section>
    </>
  );
}

export default Join;
